// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-courses-index-tsx": () => import("./../../../src/pages/courses/index.tsx" /* webpackChunkName: "component---src-pages-courses-index-tsx" */),
  "component---src-pages-courses-ux-design-index-tsx": () => import("./../../../src/pages/courses/ux-design/index.tsx" /* webpackChunkName: "component---src-pages-courses-ux-design-index-tsx" */),
  "component---src-pages-documentation-index-tsx": () => import("./../../../src/pages/documentation/index.tsx" /* webpackChunkName: "component---src-pages-documentation-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-payment-index-tsx": () => import("./../../../src/pages/payment/index.tsx" /* webpackChunkName: "component---src-pages-payment-index-tsx" */)
}

